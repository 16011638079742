import React, { FunctionComponent, useEffect, useState } from 'react';
import Section from "../components/Section";
import Hero from "../components/Hero";
import FreelanceImage from "../assets/images/freelance.png";
import Button from "../components/Button/Button";
import Heading from "../components/Heading";
import { graphql, Link } from "gatsby";
import FeaturedPostsSection from "../sections/FeaturedPostsSection";
import useTranslations from '../components/useTranslations';
import FaqsSection from '../components/Faqs/FaqsSection';
import PromoSection from '../components/PromoSection';
import CostsImage from "../assets/images/costs.png";
import LocalizedLink from '../components/LocalizedLink';

type SroProps = {
    data?: any;
};

const Freelance: FunctionComponent<SroProps> = (props) => {
    const { pages: { freelance } } = useTranslations()

    const [featuredPosts, setFeaturedPosts] = useState<any>([])
    const [faqs, setFaqs] = useState<any>([])

    useEffect(() => {
        if (props.data.freelanceFeaturedPosts.edges) {
            setFeaturedPosts(props.data.freelanceFeaturedPosts.edges)
        }
        if (props.data.freelanceFaqs.edges) {
            setFaqs(props.data.freelanceFaqs.edges)
        }

    }, [])

    return (
        <>
            <Section className='py-8' fullWidth>
                <div>
                    <Hero
                        headline={freelance.heroTitle}
                        subheadline={freelance.heroDescription}
                        heroImage={FreelanceImage}
                        buttons={(
                            <div>
                                <LocalizedLink to={'/order-form'}>
                                    <Button chevronVariant={'white'}>
                                        {freelance.actions.setup}
                                    </Button>
                                </LocalizedLink>
                                <LocalizedLink to={'#offer'}>
                                    <Button className={'ml-6 font-medium text-ink-60'} defaultStyle={'font-serif inline-flex items-center rounded-full'} variant={'transparent'} chevronVariant={'ink_60'}>
                                        {freelance.actions.learn}
                                    </Button>
                                </LocalizedLink>
                            </div>
                        )}
                        outerContainer
                    />
                </div>
            </Section>
            <Section className={'py-20'}>
                <div className={'text-center mb-20'}>
                    <Heading id="offer" type='h3' className={'text-ink-40 mb-3.5'}>{freelance.offers.title}</Heading>
                    <Heading type={'h2'}>
                        {freelance.offers.description}
                    </Heading>
                </div>
                <div className={'grid grid-cols-1 md:grid-cols-2 gap-y-32 gap-x-8 mb-12'}>
                    {freelance.offers.items.map((offer, index) => (
                        <div className={'flex'} key={`freelance-offers-${index}`}>
                            <div className={'flex w-1/5 justify-center mr-4 items-start'}>
                                <h3 className={'font-sans text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-b from-brand-100 to-white'}>
                                    {index + 1}
                                </h3>
                            </div>
                            <div className={'w-2/3'}>
                                <Heading type="h5" className={'font-semibold mb-4 text-lg'}>{offer.title}</Heading>
                                <p className={'text-ink-60 text-lg'}>{offer.text}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </Section>
            <Section className={'py-20 bg-ink-5'}>
                <div className="flex flex-wrap">
                    <div className="w-full md:w-2/3">
                        <p className={'font-caveat text-2xl font-bold text-brand-100'}>{freelance.price?.startAt ?? 1} 39 € </p>
                        <p className={'font-caveat text-xl font-bold text-ink-40 mb-3'}>+ 150 €  {freelance.price?.fee ?? 1}</p>
                        <Heading type='h2' className={'mb-8'}>{freelance.price.h2}</Heading>
                        <LocalizedLink to={'/order-form'}>
                            <Button chevronVariant={'white'} variant="secondary">{freelance.price.start}</Button>
                        </LocalizedLink>
                    </div>
                    <div className={'w-full md:w-1/3 pl-4 flex items-center'}>
                        <img src={CostsImage} alt="" />
                    </div>
                </div>
                <div className={'border-t border-ink-20 mt-16 py-6'}>
                    <p className={'text-sm text-ink-40'}>{freelance.price.disclaimer}</p>
                </div>
            </Section>
            <Section className={'py-20 bg-blue-120 text-white'}>
                <div className={'flex mb-16'}>
                    <div className={'w-full text-center'}>
                        <Heading type='h3' className={'mb-4'}>{freelance.easy.subTitle}</Heading>
                        <Heading type='h2'>{freelance.easy.title}</Heading>
                    </div>
                </div>
                <div className={'grid grid-cols-1 lg:grid-cols-3 gap-24 text-white'}>
                    {freelance.easy.items.map((item, index) => (
                        <div key={`sro-easy-${index}`}>
                            <Heading type='h5' className={'font-semibold mb-4'}>{item.title}</Heading>
                            <p className={'text-ink-20 text-lg'}>{item.text}</p>
                        </div>
                    ))}
                </div>
                <div className={'text-center mt-16'}>
                    <LocalizedLink to={'/order-form'}>
                        <Button chevronVariant={'white'} variant="secondary">{freelance.easy.setup}</Button>
                    </LocalizedLink>
                </div>

            </Section>
            <FaqsSection items={faqs} />
            <FeaturedPostsSection posts={featuredPosts} />
            <PromoSection />
        </>
    );
};

export const query = graphql`
query FreelancePage($locale: String){
    freelanceFaqs: allFaqsNode(filter: {locale: {eq: $locale}}) {
        edges {
            node {
            data {
                category
                content
                title
            }
            locale
            }
        }
    }

    freelanceFeaturedPosts: allMdx(filter: {frontmatter: {locale: {eq: $locale}}}, sort: {order: ASC, fields: frontmatter___date}) {
        edges {
            node {
                id
                fields{
                    locale
                    slug
                }
                frontmatter {
                    title
                    date(formatString: "DD MMMM YYYY")
                    page
                    featuredimage
                }
            }
        }
    }
}`

export default Freelance;
